import { graphql, Link } from 'gatsby'
import GoogleMapReact from 'google-map-react'
import React, { FormEvent, useRef, useState } from 'react'
import Layout from '../templates/layout'
import Logo from '../images/footer/logo.svg'
import SEO from '../components/SEO'
import axios from 'axios'
import Reaptcha from 'reaptcha'

interface MapMarkerProps {
	lat: number
	lng: number
}

const MapMarker = ({ lat, lng }: MapMarkerProps) => {
	return (
		<a
			target='_blank'
			href='https://goo.gl/maps/ri5Fx4BaV5ahm8yU7'
			rel='noreferrer'
		>
			<img src={Logo} alt='' className='-translate-y-1/2 z-[99]' />
		</a>
	)
}

type Props = {
	data: {
		markdownRemark: {
			frontmatter: {
				title: string
				description: string
			}
		}
	}
}

type PostContact = {
	name: string
	message: string
	telephone: string
	email: string
	'g-response': string
}

const Contact = ({ data }: Props) => {
	const formRef = useRef<any>(null)
	const recaptchaRef = useRef<any>(null)
	const formButtonRef = useRef<any>(null)

	const [name, setName] = useState('')
	const [nameError, setNameError] = useState(false)
	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState(false)
	const [phone, setPhone] = useState('')
	const [phoneError, setPhoneError] = useState(false)
	const [message, setMessage] = useState('')
	const [messageError, setMessageError] = useState(false)
	const [recaptchaToken, setRecaptchaToken] = useState('')

	const [formErrorMessage, setFormErrorMessage] = useState('')
	const [formSuccessMessage, setFormSuccessMessage] = useState('')

	const metaTags = data.markdownRemark.frontmatter

	const resetStates = () => {
		setName('')
		setNameError(false)
		setEmail('')
		setEmailError(false)
		setPhone('')
		setPhoneError(false)
		setMessage('')
		setMessageError(false)
	}

	const handleOnChange = (
		value: string,
		setState: React.Dispatch<React.SetStateAction<string>>,
		setErrorState: React.Dispatch<React.SetStateAction<boolean>>,
	) => {
		setErrorState(false)
		setState(value)
	}

	const handleSubmit = async (e: FormEvent) => {
		e.preventDefault()

		setFormErrorMessage('')
		setFormSuccessMessage('')

		if (name === '') {
			setNameError(true)
		}
		if (email === '') {
			setEmailError(true)
		}
		const phoneRegex = /^05[\d]{9}$/gm
		const phoneRegexTest = phoneRegex.test(phone)

		if (phone === '' || !phoneRegexTest) {
			setPhoneError(true)
		}
		if (message === '') {
			setMessageError(true)
		}

		if (
			name !== '' &&
			email !== '' &&
			phone !== '' &&
			phoneRegexTest &&
			message !== ''
		) {
			try {
				const { data, status } = await axios.post<PostContact>(
					'https://ekhukuk.com/send-email',
					{
						name,
						email,
						telephone: phone,
						message,
						'g-response': recaptchaToken,
					},
				)
				if (status === 200) {
					setFormSuccessMessage(data.message)
				}
				return data
			} catch (error: any) {
				recaptchaRef.current.reset()
				if (error && error.response) {
					const { data } = error.response
					setFormErrorMessage(data?.message)
				}
			}
		}
	}

	const onVerify = (recaptchaResponse: string) => {
		setRecaptchaToken(recaptchaResponse)
		formButtonRef.current.click()
	}
	return (
		<Layout border={true}>
			<SEO
				title={metaTags.title}
				description={metaTags.description}
				path='/iletisim'
			/>
			<div className='bg-cream'>
				<div className='container font-poppins'>
					<div className='flex relative w-full h-full lg:pt-20 pt-10 lg:pb-[6.375rem] pb-10'>
						<div className='md:w-10/12'>
							<div className='mb-4'>
								<Link to='/' className='mr-6 text-small text-grey'>
									Ana Sayfa
								</Link>
								<Link
									to='/iletisim'
									className='font-medium text-small text-grey'
								>
									İletişim
								</Link>
							</div>
							<h1 className='text-header font-medium'>İletişim</h1>
							<div className='text-small text-grey font-merriweather mt-2.5'>
								Yaratıcı hukuk çözümleri için iletişime geçin.
							</div>
						</div>
						<div className='map-wrapper absolute right-0 top-20 w-[56%] h-96 lg:!block !hidden'>
							<GoogleMapReact
								center={{
									lat: 40.76418,
									lng: 29.939947,
								}}
								defaultZoom={17}
								bootstrapURLKeys={{
									key: 'AIzaSyCVDoMowAgMdUtC5h0ZpaT_kqjjwOg9vAY',
								}}
								onGoogleApiLoaded={({ map, maps }) => console.log(map, maps)}
								yesIWantToUseGoogleMapApiInternals
							>
								<MapMarker lat={40.76418} lng={29.939947} />
							</GoogleMapReact>
						</div>
					</div>
				</div>
			</div>
			<div className='container lg:py-20 py-10'>
				<div className='map-wrapper w-full h-96 lg:!hidden !block mb-20'>
					<GoogleMapReact
						center={{
							lat: 40.76418,
							lng: 29.939947,
						}}
						defaultZoom={17}
						yesIWantToUseGoogleMapApiInternals
					>
						<MapMarker lat={40.76418} lng={29.939947} />
					</GoogleMapReact>
				</div>
				<form
					className='lg:w-[40%] font-poppins text-small'
					id='contact-form'
					ref={formRef}
					onSubmit={handleSubmit}
				>
					<fieldset disabled={!!formSuccessMessage}>
						<input
							type='text'
							placeholder='Adınız Soyadınız'
							className={`${
								nameError ? 'border-red-600' : 'border-grey'
							} p-4 mb-4 border w-full outline-none text-grey`}
							value={name}
							onChange={(e) =>
								handleOnChange(e.target.value, setName, setNameError)
							}
						/>
						<input
							type='email'
							placeholder='E-Posta Adresiniz'
							className={`${
								emailError ? 'border-red-600' : 'border-grey'
							} p-4 mb-4 border w-full outline-none text-grey`}
							value={email}
							onChange={(e) =>
								handleOnChange(e.target.value, setEmail, setEmailError)
							}
						/>
						<input
							type='tel'
							placeholder='Telefon Numaranız (05xxxxxxxxx)'
							className={`${
								phoneError ? 'border-red-600' : 'border-grey'
							} p-4 mb-4 border w-full outline-none text-grey`}
							value={phone}
							onChange={(e) =>
								handleOnChange(e.target.value, setPhone, setPhoneError)
							}
						/>
						<textarea
							placeholder='Mesajınız'
							className={`${
								messageError ? 'border-red-600' : 'border-grey'
							} p-4 mb-4 border w-full outline-none text-grey`}
							value={message}
							onChange={(e) =>
								handleOnChange(e.target.value, setMessage, setMessageError)
							}
						/>
						<Reaptcha
							ref={recaptchaRef}
							sitekey='6Lc5iDEgAAAAAIRTFa4ROYDwksxZHSiLyNKwqENG'
							badge='inline'
							size='invisible'
							onVerify={onVerify}
						/>
						<button
							className='bg-navyBlue text-white text-small font-medium py-2 px-4 mt-5'
							type='button'
							onClick={() => recaptchaRef.current.execute()}
						>
							Mesajı Gönder
						</button>
						<button className='hidden' type='submit' ref={formButtonRef} />
						{formErrorMessage !== '' && (
							<div className='mt-5 text-red-600 text-small font-medium'>
								{formErrorMessage}
							</div>
						)}
						{formSuccessMessage !== '' && (
							<div className='mt-5 text-green-600 text-small font-medium'>
								{formSuccessMessage}
							</div>
						)}
					</fieldset>
				</form>
			</div>
		</Layout>
	)
}

export const query = graphql`
	query ContactQuery {
		markdownRemark(fileAbsolutePath: { regex: "/contact.md/" }) {
			frontmatter {
				title
				description
			}
		}
	}
`
export default Contact
